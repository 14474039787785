import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";
import createRootReducer from "../reducers";
import { userApi } from "../../api/user/user-api";

const history = createBrowserHistory();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history,
  reduxTravelling: true,
});

const middlewares = [thunk, routerMiddleware, userApi.middleware];

export default function configureStore(preloadedState = {}) {
  const store = createStore(
    createRootReducer(routerReducer),
    preloadedState,
    compose(
      applyMiddleware(...middlewares)
    )
  );

  const reduxHistory = createReduxHistory(store);

  return { store, history: reduxHistory };
}

export { history };
