const steps = [
    {
        selector: '.symbol-tabs',
        content: 'Bist30, Bist70 ve Bist500 Hisselerinin hem hisse Sentiment değerine (0-10 arası) hem de günlük değişim grafiğine buradan ulaşabilirsiniz. ',
        position: 'right',
    },
    {
        selector: '.symbol-select',
        content: 'Bu alandan sembol seçebilirsiniz.',
        position: 'right',
    },


];

export default steps;
