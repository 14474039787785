import { Button, Form, Input, notification } from "antd";
import firebase from "firebase/app";
import { useState } from "react";
import { useIntl } from "react-intl";
import { Link, useHistory, useLocation } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { auth } from "../firebase";

const FormItem = Form.Item;

const SignIn = () => {
  const intl = useIntl();
  const { isLoading, error, userLogin, setAuthUser } = useAuth();
  const [result, setResult] = useState("");
  const history = useHistory();
  const location = useLocation();

  const onFinishFailed = (errorInfo) => { };

  const onFinish = (values) => {
    setResult("");
    userLogin(values, (err) => {
      if (err && err.code) {
        switch (err.code) {
          case "auth/user-not-found":
          case "auth/wrong-password":
            setResult(intl.formatMessage({ id: "page.login.invalid_email_or_password" }));
            notification.error({
              message: 'Giriş Başarısız',
              description: intl.formatMessage({ id: "page.login.invalid_email_or_password" }),
            });
            break;
          case "auth/operation-not-allowed":
            setResult("Kullanıcı hesabı aktif değil!");
            notification.error({
              message: 'Giriş Başarısız',
              description: 'Kullanıcı hesabı aktif değil!',
            });
            break;
          case "auth/user-disabled":
            setResult("Kullanıcı hesabı aktif değil!");
            notification.error({
              message: 'Giriş Başarısız',
              description: 'Kullanıcı hesabı aktif değil!',
            });
            break;
          case "auth/invalid-credential":
            setResult("Kullanıcı bilgileri hatalı!");
            notification.error({
              message: 'Giriş Başarısız',
              description: 'Kullanıcı bilgileri hatalı!',
            });
            break;
          default:
            setResult("Giriş sırasında hata oluştu!");
            notification.error({
              message: 'Giriş Başarısız',
              description: 'Giriş sırasında hata oluştu!',
            });
            break;
        }
      } else {
        notification.success({
          message: 'Giriş Başarılı',
          description: 'Giriş işleminiz başarıyla tamamlandı.',
        });
        history.push("/dashboard");
      }
    });
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg"></div>

            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo-white.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <h2 style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '24px', marginBottom: '20px' }}>
              Giriş Yap
            </h2>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              {result && result !== "" && (
                <Form.Item>
                  <div className="ant-form-item-explain-error">
                    <div role="alert">{result}</div>
                  </div>
                </Form.Item>
              )}
              <Form.Item
                rules={[{ required: true, message: intl.formatMessage({ id: "page.login.invalid_email" }) }]}
                name="email"
              >
                <Input placeholder={intl.formatMessage({ id: "page.login.email" })} type="email" />
              </Form.Item>
              <Form.Item
                rules={[{ required: true, message: intl.formatMessage({ id: "page.login.please_enter_your_password" }) }]}
                name="password"
              >
                <Input type="password" placeholder={intl.formatMessage({ id: "page.login.password" })} />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  className="gx-mb-0 gx-rounded-button"
                  htmlType="submit"
                  style={{ width: "100%", borderRadius: "25px" }}
                >
                  <IntlMessages id="page.login.login" />
                </Button>
                <div style={{ textAlign: 'center', marginTop: '10px', position: 'relative' }}>
                  <span className="or-line"></span>
                  <span style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'white', padding: '0 10px' }}>
                    <IntlMessages id="page.login.or" />
                  </span>
                  <span className="or-line"></span>
                </div>
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                  <Link to={`/signup${location.search ?? ''}`}><IntlMessages id="page.login.signup" /></Link>
                </div>

              </Form.Item>

              <Button
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                  borderRadius: "25px"
                }}
                onClick={() => {
                  var provider = new firebase.auth.GoogleAuthProvider();
                  auth.signInWithPopup(provider).then(() => {
                    notification.success({
                      message: 'Giriş Başarılı',
                      description: 'Google ile giriş işleminiz başarıyla tamamlandı.',
                    });
                    history.push("/dashboard");
                  }).catch((err) => {
                    notification.error({
                      message: 'Giriş Başarısız',
                      description: 'Google ile giriş sırasında hata oluştu!',
                    });
                  });
                }}
              >
                <img height="16" src="/assets/images/google.png" />
                <IntlMessages id="page.login.signin_with_google" />
              </Button>
              <Link className="forget-password-link" to="/forgot-password" style={{ display: "block", textAlign: "center", marginTop: "10px" }}>
                <IntlMessages id="page.login.lost_password" />
              </Link>
            </Form>
          </div>
          <AppNotificationContainer loading={isLoading} error={error} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;