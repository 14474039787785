import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";
import { useDispatch } from "react-redux";

import configureStore, { history } from "./appRedux/store";
import App from "./containers/App/index";
import { AuthProvider } from "./authentication";

const { store } = configureStore();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

const NextApp = () => (
  <Provider store={store}>
    <Router history={history}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  </Provider>
);

export default NextApp;
